.ck-content{
    border: none;
    height: 60vh;
    width:100%;
    max-width: 80vw;
    background-color: #fff;
    padding:2;
    overflow-y: auto;
}

:is(ol,ul){
    padding-left: 20px;
}