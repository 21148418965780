/* @import url('./utils/commonCssProperties.js'); */

:root {
  /* background */
  --primary-background-color: rgba(247, 247, 247, 1);
  --secondary-background-color: rgba(255, 255, 255, 1);
  /* border */
  --primary-border-color: rgba(163, 163, 163, 1);
}

@import url('./assets/styles/fonts.css');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* background-color: rgba(247, 247, 247, 1); */
  font-family: "Graphik", sans-serif !important;
}

body{
  background-color: var(--primary-background-color);
}


.error {
  color: red;
}


.canvas-container {
  height: 40vh;
  padding: 0px;
}

.canvas-container-curve-chart{
  height: 32vh;
}

.ql-container.ql-snow{
  border: none !important;
}
.ql-font-serif {
  font-family: 'Georgia', serif;
}
.ql-font-monospace {
  font-family: 'Monaco', monospace;
}