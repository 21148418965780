.ql-container {
    width: 100%;
    padding: 16px;
  }
  
  .ql-header {
    display: flex;
    justify-content: space-between;
    padding: 16px;
  }
  
  .ql-header .ql-header-item {
    font-weight: 600;
    color: green;
  }
  
  .ql-header .ql-header-item-center {
    text-align: center;
  }
  
  .ql-header .ql-header-item-right {
    text-align: right;
  }
  
  .ql-main-content {
    background-color: #FFFFFF;
    border: 1px solid rgba(213, 213, 213, 1);
    border-radius: 8px;
    min-height: 50vh;
    width: 100%;
    word-break: break-word;
    position: relative;
  }
  
  .ql-main-content-header {
    display: flex;
    justify-content: space-between;
    padding: 16px;
  }
  
  .ql-main-content-header img {
    display: block;
  }
  
  .ql-main-content-body {
    border: 1px solid #BDBDBD;
    margin-top: 32px;
    padding: 16px;
  }
  
  .ql-table {
    display: flex;
    flex-wrap: wrap;
  }
  
  .ql-table-item {
    display: flex;
    border: 1px solid #BDBDBD;
    padding: 8px;
  }
  
  .ql-table-item-25 {
    width: 25%;
  }
  
  .ql-table-item-75 {
    width: 75%;
  }
  
  .ql-table-item-bold {
    font-weight: 600;
  }
  
  .ql-table-item-bg {
    background-color: #BDBDBD;
  }
  
  .ql-full-width {
    width: 100%;
  }
  
  .ql-signature-section {
    font-weight: 600;
    padding: 8px;
  }
  
  .ql-signature-section img {
    width: 120px;
    height: 50px;
  }
  
  .ql-signature-section span {
    float: right;
  }
  