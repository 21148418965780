@font-face {
    font-family: 'Graphik';
    src: url('../fonts/GraphikRegular.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Graphik';
    src: url('../fonts/GraphikLight.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Graphik';
    src: url('../fonts/GraphikThin.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
}

body {
    font-family: 'Graphik', sans-serif !important;
}

